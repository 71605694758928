/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    cart: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A.5.5 0 01.5 1H2a.5.5 0 01.485.379L2.89 3H14.5a.5.5 0 01.491.592l-1.5 8A.5.5 0 0113 12H4a.5.5 0 01-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 01-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 100 4 2 2 0 000-4m7 0a2 2 0 100 4 2 2 0 000-4m-7 1a1 1 0 110 2 1 1 0 010-2m7 0a1 1 0 110 2 1 1 0 010-2"/>',
    },
});
